import React, { useEffect, useState } from 'react'
import { GetData } from '../services/GetData'
import BotonTabla from '../components/BotonTabla'
import Swal from 'sweetalert2'
import { PostData } from '../services/PostData'
import {validarEmail} from '../components/utils'
import { useParams } from 'react-router-dom'
import { URL_INFORMES } from '../services/UrlWebServis'
import { getSede, getToken } from '../services/Usuario'

const ListaAlumnos = () => {   
   
   const { grado, grupo } = useParams();
   const [ListaTiposUS, setListaTiposUS] = useState([]) 
   const [TiposUsuario, setTiposUsuario] = useState('Todos') 
   const [ListadoAlumnos, setListadoAlumnos] = useState([]) 
   const [Loading, setLoading] = useState(true)
   const [NombreGrado, setNombreGrado] = useState("")

   const [LoadingForm, setLoadingForm] = useState(false)
   const [ModIdMatricula, setModIdMatricula] = useState(0)
   const [EstadoMat, setEstadoMat] = useState(null)
   const [Nombres, setNombres] = useState("")  
   const [Telefono, setTelefono] = useState("")  
   const [Direccion, setDireccion] = useState("")  
   const [TipoIden, setTipoIden] = useState("")  
   const [Identificacion, setIdentificacion] = useState("")  
   

   useEffect(() => {
        const datos = {
            id_grado: grado,
            id_grupo: grupo  
        }
        setLoading(true)
        PostData('matriculas/alumnosgradoadmin', datos).then ((result) =>{
            let responseJSON = result;

            if (responseJSON.error === false){
                setListadoAlumnos(result.alumnos)
                setNombreGrado(result.grado)
                setLoading(false)
            }
        })

    }, []) 

    const VerDatos = (dato) => {
        
                setModIdMatricula(dato.id_matricula)
                setNombres(dato.nombres_apellidos_al)
                setTelefono(dato.telefono_al)
                setDireccion(dato.direccion_al)
                setEstadoMat(dato.id_estado_matricula)
         

        const modal = document.getElementById('modalD');

        // Verificar si el modal existe antes de intentar abrirlo
        if (modal) {
          // Usar el método modal() para abrir el modal
          const modalInstancia = new window.bootstrap.Modal(modal);
          modalInstancia.show();
        }
    }

    

  return (
    <>
        <section className='section dashboard'>
            <div class="card">
                  
                <div className="card-body pb-0">
                    <h5 className="card-title">Listado Alumnos   
                    </h5>

                    <div className='row'>
                             <div className='col-md-5' style={{marginTop: '5px'}}>
                                <span className='ml-4 label'>Grado:</span> <span className='texto'>{NombreGrado} - 0{grupo}</span>  
                                <span className='ml-4 label'>N° Alumnos: </span> <span className='texto'>{ListadoAlumnos.length}</span>                                
                            </div>
                            <div className='col-md-5'>
                                <div className="btn-group " role="group">
                                    <button id="btnGroupDrop1" type="button" className="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                    Seleccione un Informe
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                    <li><a className="dropdown-item" target="_blank"  href={URL_INFORMES + `listado_alumnos_asistenc.php?grado=${grado}&grupo=${grupo}&doc=0&token=${getToken()}&db=${getSede()}`}>Listado de Asistencia</a></li>
                                    <li><a className="dropdown-item" target="_blank"  href={URL_INFORMES + `listado_alumnos_datos.php?grado=${grado}&grupo=${grupo}&doc=0&token=${getToken()}&db=${getSede()}`}>Listado con Datos</a></li>
                                    <li><a className="dropdown-item" target="_blank"  href={URL_INFORMES + `listado_alumnos_firma.php?grado=${grado}&grupo=${grupo}&doc=0&token=${getToken()}&db=${getSede()}`}>Formato Entrega de Informes</a></li>
                                    <li><a className="dropdown-item" target="_blank"  href={URL_INFORMES + `lisadoalumnosasistencia_xls.php?grado=${grado}&grupo=${grupo}&doc=0&token=${getToken()}&db=${getSede()}`}>Listado de Asistencia Excel</a></li>
                                    <li><a className="dropdown-item" target="_blank"  href={URL_INFORMES + `lisadoalumnosdatos_xls.php?grado=${grado}&grupo=${grupo}&doc=0&token=${getToken()}&db=${getSede()}`}>Listado de Datos Excel</a></li>
                                    </ul>
                                </div>
                            </div>
                    </div>
                    {Loading && 
                    <div class="progress mt-3">
                        <div class="progress-bar progress-bar-striped bg-info progress-bar-animated" role="progressbar" style={{width : '100%'}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>      
                    }      
                    <table class="table table-striped  mt-2">
                        <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col"> Nombre Alumno</th>
                            <th scope="col">Teléfono</th>
                            <th scope="col">Dirección</th>
                            <th scope="col">Estado</th>
                            <th scope="col">Op</th>
                        </tr>
                        </thead>
                        <tbody>
                        {ListadoAlumnos.map((us, index) => 
                        <tr index={us.id_matricula}>
                            <th scope="row">{index+1}</th>
                            <td> <a href='#' onClick={()=> VerDatos(us)} title='Ver Alumno' >{us.nombres_apellidos_al}</a></td>
                            <td>{us.telefono_al}</td>
                            <td>{us.direccion_al}</td>
                            <td>{us.id_estado_matricula === "1" ? <span class="badge bg-success">Activo</span> : <span class="badge bg-secondary">Inactivo</span>}</td>
                            <td>
                                
                            </td>
                        </tr>
                        )}    
                        
                        </tbody>
                    </table>
               </div>
            </div>
        </section>            

        <div className="modal fade" id="modalD" tabindex="-1" data-bs-backdrop="static">
                <div className="modal-dialog modal-lg">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title"><i className="bi bi-pencil-square"></i> Ver Datos ALumno {ModIdMatricula}</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        {LoadingForm && 
                        <div className="progress mt-3">
                            <div className="progress-bar progress-bar-striped bg-info progress-bar-animated" role="progressbar" style={{width : '100%'}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>      
                        }     
                        
                        <div className='row mt-2'>
                           
                            <div className='col-md-12'>
                                <span className='label'>Nombres y Apellidos: </span>
                                <input type="text" 
                                    className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={Nombres} disabled />
                            </div>     
                            <div className='col-md-6'>
                                <span className='label'>Teléfono: </span>
                                <input type="text" 
                                    className="form-control form-control-sm estilocampo" id="nombres" name="telefono" value={Telefono} disabled />
                            </div>     
                            <div className='col-md-6'>
                                <span className='label'>Dirección: </span>
                                <input type="text" 
                                    className="form-control form-control-sm estilocampo" id="nombres" name="direccion" value={Direccion} disabled />
                            </div>     
                            
                        </div>
                      </div>
                        <div className="modal-footer">
                                <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal"><i className="bi bi-door-closed"></i> Cerrar</button>
                                <button type="button" className="btn btn-primary btn-sm" onClick=""  data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-original-title="Guardar Usuario"><i className="bi bi-clipboard-plus"></i> Guardar</button>                      
                         </div>
                 </div>
            </div>
        </div>                        
    </>
  )
}

export default ListaAlumnos