import React, { useContext, useEffect, useState } from 'react'
import { NavBar } from '../includes/NavBar'
import SideBar from '../includes/SideBar'
import HomePrincipal from './HomePrincipal'
import NuevaPersona from './NuevaPersona'
import { ListaPersonas } from './ListaPersonas'
import { Route, Routes, useNavigate } from 'react-router-dom'
import NotFound from './NotFound'
import PerfilUsuario from './PerfilUsuario'
import config from '../config'
import { GetData } from '../services/GetData'
import NuevoUsuario from './NuevoUsuario'
import ListaUsuarios from './ListaUsuarios'
import Desempenios from './Desempenios'
import CargaAcademica from './CargaAcademica'
import DesempeniosComport from './DesempeniosComport'
import Matriculas from './Matriculas'
import { SessionContext } from '../SessionContext';
import { PostData } from '../services/PostData'
import DirectoresGrupo from './DirectoresGrupo'
import Docentes from './Docentes'
import HojaMatricula from './HojaMatricula'
import 'animate.css';
import Consolidados from './Consolidados'
import NotasIndicador from './NotasIndicador'
import ListaAlumnos from './ListaAlumnos'
import PorcDesempGrados from './EstadisticaGrados/PorcDesempGrados'
import PromTodosPeriodosGrado from './EstadisticaGrados/PromTodosPeriodosGrado'
import EstadisticaGradoPeriodo from './EstadisticaGrados/EstadisticaGradoPeriodo'
import DesempDocentesPeriodo from './EstadisticaGrados/DesempDocentesPeriodo'
import AlumnosMas2AsigPerdidasPer from './Informes/AlumnosMas2AsigPerdidasPer'
import RankinAlumnosPeriodo from './Informes/RankinAlumnosPeriodo'
import { Usuario } from '../services/Usuario'
import VerHojaMatricula from './VerHojaMatricula'
import ConfigurarPeriodos from './ConfigurarPeriodos'
import MejorPuntajeFinales from './Informes/MejorPuntajeFinales'

const HomePage = () => {

    const [DatosInstitucion, setDatosInstitucion] = useState([])
    const [SedeActual, setSedeActual] = useState(1)
    const { selectedCampus, setSelectedCampus } = useContext(SessionContext);
    const [BuscarAlumno, setBuscarAlumno] = useState("")
    const [ListadoAlumnos, setListadoAlumnos] = useState([])

    const navigate = useNavigate();
    const usuario = Usuario();
    const tipousario = parseInt(usuario.id_tipos_usuario)

  /*   useEffect(() => {
      document.body.classList.add('toggle-sidebar'); // Agregar clase al cargar el componente
  
      return () => {
        document.body.classList.remove('toggle-sidebar'); // Remover clase al desmontar el componente
      };
    }, []); */

    const handleToggleSidebar = () => {
      const body = document.body;
      const hasToggleSidebarClass = body.classList.contains('toggle-sidebar');
  
      if (hasToggleSidebarClass) {
        body.classList.remove('toggle-sidebar');
      } else {
        body.classList.add('toggle-sidebar');
      }
    };

    useEffect(() => {
      GetData('institucion/datosinstitucion', null).then ((result) =>{
        if (result.error === false){
            setDatosInstitucion(result.datos[0]);
        }
    })
      
    }, [])

    useEffect(() => {
      const data = {
          nombres: BuscarAlumno
      }
      PostData('matriculas/buscarnombre/', data).then ((result) =>{
        if (result.error === false){
          setListadoAlumnos(result.matriculas);
        }
    })
      
    }, [BuscarAlumno])
    


    const seleccionarSede = (sede) => {
         setSedeActual(sede);                 
    } 

    const cambiarSede = () => {
          
          let sede = 'default'

          switch (SedeActual) {
            case 1:
                 sede = 'default'
                 break;
            case 2:
                 sede = 'dbprado'
                 break;
            case 3:
                 sede = 'dbsmartin'
                 break;
            default:
                sede = 'default'
                break;
          } 
          setSelectedCampus(sede);
          GetData('institucion/datosinstitucion', null).then ((result) =>{
            if (result.error === false){
                setDatosInstitucion(result.datos[0]);
                window.location.reload();
            }           
          })
    }

    const ModalSedes = () => {
      const modal = document.getElementById('modalSede');

      // Verificar si el modal existe antes de intentar abrirlo
      if (modal) {
        // Usar el método modal() para abrir el modal
        const modalInstancia = new window.bootstrap.Modal(modal);
        modalInstancia.show();
      }
    }
    
    const matriculaAlumno = (idmatricula) => {
      setListadoAlumnos([])
      setBuscarAlumno("")
      navigate('verhojamatricula/'+idmatricula)
    }
  return (
    <>
        <header id="header" className="header fixed-top d-flex align-items-center">

        <div className="d-flex align-items-center justify-content-between ">
          <a href="/home" className="logo d-flex align-items-center animate__animated animate__flipInY">
            <img src="/assets/img/cnotas.png" alt="" />
            <span className="d-none d-lg-block">{config.NOMBRE_APP}</span>
          </a>
          <i className="bi bi-list toggle-sidebar-btn" onClick={handleToggleSidebar}></i>
        </div>

        <div className="search-bar">
          <form className="search-form d-flex align-items-center" method="POST" action="#" onSubmit={(e) => e.preventDefault()} >
            <input type="text" name="query" autocomplete="off" placeholder="Buscar" title="Ingrese el nombre del alumno a buscar" value={BuscarAlumno} onChange={(e)=> setBuscarAlumno(e.target.value)} />
            <button type="submit" title="Search"><i className="bi bi-search"></i></button>
          </form>
          {ListadoAlumnos.length > 0 && BuscarAlumno !== "" && 
              <div className="list-group position-absolute top-100 start-1 search-flotante" style={{marginTop: '-11px'}}>
                {ListadoAlumnos.map((alumno, index) => (
                  <button key={index} type="button" className="list-group-item list-group-item-action" onClick={()=> matriculaAlumno(alumno.id_matricula)}>
                    {alumno.sexo === 'M' ? (
                      <img src='/assets/img/avatar_hombre.png' width={20} alt='Avatar Hombre' />
                    ) : (
                      <img src='/assets/img/woman.png'  width={20} alt='Avatar Mujer' />
                    )} {alumno.nombres_apellidos_al} <span className='gradosearch'>({alumno.num_grado}-{alumno.num_grupo})</span>
                  </button>
                ))}
              
            </div>
        }



        </div>

        <div className="search-bar">
               <strong>Sede</strong> <span className='card-title'>{DatosInstitucion.sede}</span>  
               {(tipousario === 0 || tipousario === 1 || tipousario === 4) && 
                   <button type="submit" className='btn btn-primary btn-sm ml-2' title="Cambiar sede actual" onClick={() => ModalSedes()}><i className="bi bi-signpost-2"></i> Cambiar sede</button>          
               }
        </div>


            <NavBar />

        </header>

        {/* *** Sidebar ** */}
            <SideBar />

            <main id="main" className="main">
              <div className='container'>
              <Routes>
                <Route path="/" element={<HomePrincipal />} />
                <Route path="/principal" element={<HomePrincipal />} />
                <Route path="nuevousuario" element={<NuevoUsuario />} />
                <Route path="nuevousuario/:id" element={<NuevoUsuario />} />
                <Route path="matriculas" element={<Matriculas />} />
                <Route path="listadoalumnos/:grado/:grupo" element={<ListaAlumnos />} />
                <Route path="hojamatricula" element={<HojaMatricula />} />
                <Route path="verhojamatricula/:idmatricula" element={<VerHojaMatricula />} />
                <Route path="procdesempgrados" element={<PorcDesempGrados />} />
                <Route path="promediostodosgrados" element={<PromTodosPeriodosGrado />} />
                <Route path="estadisgradoperiodo" element={<EstadisticaGradoPeriodo />} />
                <Route path="desempdocentesperiodo" element={<DesempDocentesPeriodo />} />
                <Route path="alummas2peridasper" element={<AlumnosMas2AsigPerdidasPer />} />
                <Route path="rankinperiodo" element={<RankinAlumnosPeriodo />} />
                <Route path="cargaacademica" element={<CargaAcademica />} />
                <Route path="docentes" element={<Docentes />} />
                <Route path="configperiodos" element={<ConfigurarPeriodos />} />
                <Route path="notasindicador" element={<NotasIndicador />} />
                <Route path="consolidado/:grado/:grupo" element={<Consolidados />} />
                <Route path="directoresgrupo" element={<DirectoresGrupo />} />
                <Route path="desempenios" element={<Desempenios />} />
                <Route path="desempenioscomport" element={<DesempeniosComport />} />
                <Route path="perfilusuario" element={<PerfilUsuario />} />
                <Route path="listadousuarios" element={<ListaUsuarios />} />
                <Route path="alumnosmejorespuntajes" element={<MejorPuntajeFinales />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
              </div>
            </main>


        <footer id="footer" className="footer">
        <div className="copyright">
          &copy; Copyright <strong><span>{config.EMPRESA}</span></strong>. All Rights Reserved
        </div>
        <div className="credits">  
          Designed by <a href={config.URL_EMPRESA}>{config.EMPRESA}</a>
        </div>
        </footer>

        <a href="#" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>

        <div className="modal fade" id="modalSede" tabindex="-1">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Seleccionar Sede - {selectedCampus}</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">

                            <h3 className='card-title'>Seleccione una sede</h3>
                           
                              <div className="list-group">
                              <button
                                type="button"
                                className={`list-group-item list-group-item-action ${SedeActual === 1 ? 'active' : ''}`}
                                onClick={() => seleccionarSede(1)}
                            >
                                Sede Principal
                            </button>
                            <button
                                type="button"
                                className={`list-group-item list-group-item-action ${SedeActual === 2 ? 'active' : ''}`}
                                onClick={() => seleccionarSede(2)}
                            >
                                Sede Prado
                            </button>
                            <button
                                type="button"
                                className={`list-group-item list-group-item-action ${SedeActual === 3 ? 'active' : ''}`}
                                onClick={() => seleccionarSede(3)}
                            >
                                Sede San Martin
                            </button>
                                </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                      <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={()=>cambiarSede()} >Seleccionar Sede</button>
                    </div>
                  </div>
                </div>
              </div>    

       
    </>
  )
}

export default HomePage