// config.js
const config = {
    LOGO: '/asset/img/logo.png',
    VERSION_APP: 1.5,
    NOMBRE_APP: 'CNotas',
    API_URL_PPAL: 'https://adminapi.inscandelaria.com/index.php/',
    //API_URL_PPAL: 'http://127.0.0.1/api_candelaria/api_res_admin/index.php/',
    API_URL_INFORMES: 'https://inscandelaria.com/adminapi/printer/',
    API_URL_PATH: 'https://inscandelaria.com/',
    EMPRESA: 'Innovacion Digital',
    URL_EMPRESA: 'https://innovacionsoft.com/'
  };
  
  export default config;
  